import { add } from 'date-fns';
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core';

@Injectable({
  providedIn: 'root'
})
export class VisitorPricesService extends HttpService {
  protected override get baseUrl(): string {
    return "reservationprice/";
  }

  getAll(body:any) {
    return this.post<any>({ APIName: "get", body: body });
  }

  getById(id:any) {
    return this.get<any>({ APIName: `get/${id}` });
  }

  add(body:any) {
    return this.post<any>({ APIName: "add", body: body });
  }
  update(body:any) {
    return this.put<any>({ APIName: "update", body: body });
  }

}
