import { Component, forwardRef, Input } from "@angular/core";
import { ReactiveFormsBaseComponent } from "../../base-components";
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { MatSliderModule } from "@angular/material/slider";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-slider",
  standalone: true,
  imports: [MatSliderModule, ReactiveFormsModule, TranslateModule],
  templateUrl: "./slider.component.html",
  styleUrl: "./slider.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderComponent),
      multi: true,
    },
  ],
})
export class SliderComponent extends ReactiveFormsBaseComponent {
  @Input() min = 0; // Minimum value for the range
  @Input() max = 100; // Maximum value for the range
  @Input() step = 1; // Step size for the slider
  @Input() unit = "OMR"; // Unit of the range
  @Input() label!: string;

  controlValue!: [number, number]


  // Write value from the form control into the component
  override writeValue(value: [number, number]): void {
    if (value) {
      this.controlValue = value;
    }
  }

  // Handle changes in the slider value
  updateValue(newValue: [number, number]): void {
    this.controlValue = newValue;
    this.onChange(newValue);
    this.onTouched(newValue);
  }

  ngOnInit(): void {
    this.controlValue = [this.min, this.max]; // Range value [start, end]
  }
}
