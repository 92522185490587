import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { Injectable, inject } from "@angular/core";
import { BehaviorSubject, Observable, map } from "rxjs";

import { API_Response, TableOptions } from "../../interfaces";

@Injectable({
  providedIn: "root",
})
export class TableService {
  private http = inject(HttpClient);
  private toastrService = inject(ToastrService);
  private translation = inject(TranslateService);

  private body: TableOptions["bodyOptions"] = {
    pageNumber: 1,
    pageSize: 10,
    orderByValue: [{ colId: "id", sort: "asc" }],
    filter: null,
  };

  searchNew$ = new BehaviorSubject(null);

  getData(url: string) {
    return this.http.get<API_Response<Record<string, unknown>[]>>(`${url}`);
  }

  postData(url: string, body?: TableOptions["bodyOptions"]): Observable<any> {
    return this.http.post(`${url}`, body ? body : this.body);
  }

  delete(url: string, id: string): Observable<any> {
    return this.http.delete(`${url}/${id}`).pipe(
      map((event: any) => {
        this.alertHandling(event);
        return event.data;
      }),
    );
  }

  private alertHandling(event: API_Response<any>) {
    if (event.status) {
      if (event.status.toString().startsWith("2")) {
        this.toastrService.success(event.message ? this.translation.instant(`BE_VALIDATION.${event.message}`) : "Successfully Done...");
      } else {
        this.toastrService.error(event.message ? this.translation.instant(`BE_VALIDATION.${event.message}`) : "!NOT HANDLED ERROR!");
      }
    }
  }
}
