import { Routes } from "@angular/router";

export const membershipsListRoutes: Routes = [
  {
    path: "",
    title: "FRIENDS_OF_OMAN_MUSEUM.MEMBERSHIPS",
    loadComponent: () => import("./pages/memberships-list/memberships-list.component").then((m) => m.MembershipsListComponent),
  },
];

export const membershipsRoutes: Routes = [
  {
    path: "",
    data: {
      tabs: [
        {
          key: "AddMembership",
          title: "FRIENDS_OF_OMAN_MUSEUM.ADD_MEMBERSHIP",
          routerLink: "add",
        },
        {
          key: "RenewMembership",
          title: "FRIENDS_OF_OMAN_MUSEUM.RENEW_MEMBERSHIP",
          routerLink: "renew",
        },
      ],
    },
    loadComponent: () =>
      import("src/app/shared/base-components/base-tabs-management/base-tabs-management.component").then(
        (c) => c.BaseTabsManagementComponent,
      ),
    children: [
      {
        path: "add",
        title: "FRIENDS_OF_OMAN_MUSEUM.ADD_MEMBERSHIP",
        loadComponent: () => import("./pages/add-edit-membership/add-edit-membership.component").then((m) => m.AddEditMembershipComponent),
      },
      {
        path: "renew",
        title: "FRIENDS_OF_OMAN_MUSEUM.RENEW_MEMBERSHIP",
        loadComponent: () => import("./pages/renew-membership/renew-membership.component").then((m) => m.RenewMembershipComponent),
      },
    ],
  },
  {
    path: "edit/:id",
    title: "FRIENDS_OF_OMAN_MUSEUM.EDIT_MEMBERSHIP",
    loadComponent: () => import("./pages/add-edit-membership/add-edit-membership.component").then((m) => m.AddEditMembershipComponent),
  },
];
