import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { Component, ElementRef, Input, TemplateRef, ViewChild, inject } from "@angular/core";

import { environment } from "src/environments/environment";
import { AuthService, Service, TourServicesCodes } from "src/app/core";

import { ButtonComponent } from "../button/button.component";
import { LocalizedNamePipe } from "../../pipes";

@Component({
  selector: "app-service-card",
  standalone: true,
  imports: [TranslateModule, MatDialogModule, ButtonComponent, LocalizedNamePipe],
  templateUrl: "./service-card.component.html",
  styleUrl: "./service-card.component.scss",
})
export class ServiceCardComponent {
  private router = inject(Router);
  private dialog = inject(MatDialog);
  private authService = inject(AuthService);

  get isValidForLoggedUsersOnly() {
    return [
      TourServicesCodes.SchoolUniversityVisits,
      TourServicesCodes.TourismCompanies,
      TourServicesCodes.OfficialInstitutions,
      TourServicesCodes.GuidedTours,
      TourServicesCodes.FriendsOfOmanMuseum,
    ].includes(this.service.code as TourServicesCodes);
  }

  @Input() service!: Service;
  @Input() titleClass!: string;
  @Input() imageDisplaying = true;

  @ViewChild("loginModal") loginModal!: TemplateRef<any>;
  @ViewChild("serviceCard", { static: true }) serviceCard!: ElementRef<HTMLDivElement>;

  openModal() {
    if (localStorage.getItem(environment.tokenPropertyName)) {
      this.router.navigate([this.service.serviceRoute]);
    } else {
      this.dialog.open(this.loginModal, {
        panelClass: ["small", "h-max-content", "p-0"],
        autoFocus: false,
      });
    }
  }

  loginAsGuest() {
    this.authService.loginAsGuest().subscribe(() => {
      this.dialog.closeAll();
      this.router.navigate([this.service.serviceRoute]);
    });
  }

  login() {
    this.dialog.closeAll();
    this.router.navigate(["/auth/login"], { queryParams: { redirect: this.service.serviceRoute } });
  }

  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.

    if (this.imageDisplaying) {
      this.serviceCard.nativeElement.style.setProperty(
        "background-image",
        `url(../../../../assets/images/services-images/${this.service.code}.png)`,
      );
    }
  }
}
