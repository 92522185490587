@if (form) {
  <div class="pb-4" [formGroup]="form">
    <div class="row" formGroupName="membershipCompanyDetails">
      <!-- companyName -->
      <div class="col-sm-6 col-lg-4">
        <app-input-text label="FIELDS.COMPANY_NAME" formControlName="companyName" />
      </div>

      <!-- crNumber -->
      <div class="col-sm-6 col-lg-4">
        <app-input-text label="FIELDS.CR_NUMBER" formControlName="crNumber" />
      </div>

      <!-- ceoName -->
      <div class="col-sm-6 col-lg-4">
        <app-input-text label="FIELDS.CEO_NAME" formControlName="ceoName" />
      </div>

      <!-- countryId -->
      <div class="col-md-6 col-lg-4">
        <app-dropdown label="FIELDS.COUNTRY" formControlName="countryId" [options]="countries()!" (selected)="getGovernorates($event)" />
      </div>

      <!-- governorateId -->
      <div class="col-md-6 col-lg-4">
        <app-dropdown
          label="FIELDS.GOVERNORATE"
          formControlName="governorateId"
          [options]="governorates()!"
          (selected)="getStates($event)"
        />
      </div>

      <!-- stateId -->
      <div class="col-md-6 col-lg-4">
        <app-dropdown label="FIELDS.STATE" formControlName="stateId" [options]="states()!" />
      </div>

      <!-- phone -->
      <div class="col-md-6 col-lg-4">
        <app-input-text label="FIELDS.MOBILE" formControlName="phone" contentType="number">
          <span class="bg-transparent" matPrefix>+968</span>
        </app-input-text>
      </div>

      <!-- email -->
      <div class="col-md-6 col-lg-4">
        <app-input-text label="FIELDS.EMAIL" formControlName="email" contentType="email" />
      </div>

      <!-- zipCode -->
      <div class="col-sm-6 col-lg-4">
        <app-input-text label="FIELDS.ZIP_CODE" formControlName="zipCode" contentType="number" />
      </div>
    </div>
  </div>
}
