import { TranslateModule } from "@ngx-translate/core";
import { MatSelect, MatSelectModule } from "@angular/material/select";
import { Subject, debounceTime, takeUntil } from "rxjs";
import { ReactiveFormsModule, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation, forwardRef } from "@angular/core";

import { ReactiveFormsBaseComponent } from "../../base-components";
import { LocalizedNamePipe, ValidationHandlerPipe } from "../../pipes";

import { Dropdown } from "src/app/core/interfaces";

@Component({
  selector: "app-dropdown",
  standalone: true,
  imports: [ReactiveFormsModule, MatSelectModule, TranslateModule, ValidationHandlerPipe, LocalizedNamePipe],
  templateUrl: "./dropdown.component.html",
  styleUrl: "./dropdown.component.scss",
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent extends ReactiveFormsBaseComponent {
  @Input({ required: true }) label = "";
  @Input({ required: true }) options!: Dropdown[];

  @Input() bindValue: keyof Dropdown = "id";
  @Input() placeholder = "ACTIONS.SEARCH";
  @Input() withSearch = false;
  @Input() multiple = false;
  @Input() readonly = false;

  @Output() selected = new EventEmitter();

  filterControl = new FormControl<string | null>(null);
  filteredOptions: Dropdown[] = [];
  filteredGroupsOptions: { name: string; options: Dropdown[] }[] = [];

  private originalGroupsOptions: { name: string; options: Dropdown[] }[] = [];
  private destroy$ = new Subject();

  get isHaveGroupBy() {
    return this.options?.find((option) => option.groupBy) !== undefined;
  }

  getSelectedValue(event: { source: MatSelect; value: Dropdown["id"] | Dropdown["id"][] }) {
    let selectedValue = event.value;

    if (this.multiple && Array.isArray(selectedValue)) {
      if (selectedValue[0] === null) {
        this.control.setValue(null);
        this.selected.emit(null);
        return;
      }
    }

    this.selected.emit(event.value);
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if (this.withSearch) {
      this.filterControl.valueChanges.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe((searchKey) => {
        if (this.isHaveGroupBy) {
          this.filterGroupsOptions(searchKey);
        } else {
          if (!searchKey?.length) {
            this.filteredOptions = this.options;
            return;
          }

          this.filteredOptions = this.options.filter(
            (each) =>
              each.nameEn.toLowerCase().includes(searchKey.toLowerCase()) || each.nameAr.toLowerCase().includes(searchKey.toLowerCase()),
          );
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes["options"]) {
      this.filteredOptions = changes["options"].currentValue;
    }

    if (this.isHaveGroupBy) {
      this.generateGroupsOptions();
      this.filteredGroupsOptions = this.originalGroupsOptions;
    }
  }

  /**
   *
   * @description using `reduce` method to generate new array contains non duplicated `groupBy` texts.
   * @description check if the `previousValue` don't have the `groupBy` text then push it to the `previousValue` array if the condition is `true`
   * @description return an array according to `groupsOptions`'s interface.
   */
  private generateGroupsOptions() {
    this.originalGroupsOptions = this.options
      .reduce((previousValue: string[], currentValue) => {
        // check if the `previousValue` don't have the `groupBy` text then push it to the `previousValue` array if the condition is `true`
        if (!previousValue.includes(currentValue.groupBy as string)) previousValue.push(currentValue.groupBy as string);

        return previousValue;
      }, [])
      .map((groupBy) => ({
        name: groupBy as string,
        options: this.options.filter((option) => option.groupBy === groupBy),
      }));
  }

  /**
   *
   * @param searchKey: `string | null`
   * @description filter in `groupsOptions` array based on `searchKey` value.
   */
  private filterGroupsOptions(searchKey: string | null) {
    this.generateGroupsOptions();

    if (!searchKey?.length) {
      this.filteredGroupsOptions = this.originalGroupsOptions;
      return;
    }

    this.filteredGroupsOptions = this.originalGroupsOptions
      .filter((each) => {
        each.options = each.options.filter(
          (option) =>
            option.nameEn.toLowerCase().includes(searchKey.toLowerCase()) || option.nameAr.toLowerCase().includes(searchKey.toLowerCase()),
        );
        return each.options;
      })
      .filter((each) => each.options.length);
  }

  compareWithFn = (valueOption: Dropdown, dropdownOption: Dropdown) => {
    const HAS_ID = valueOption?.[this.bindValue] && dropdownOption?.[this.bindValue];

    return HAS_ID ? valueOption[this.bindValue] === dropdownOption[this.bindValue] : valueOption == dropdownOption;
  };

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
