import { Routes } from "@angular/router";

import { membershipsListRoutes, membershipsRoutes } from "./nested-features/memberships";

export const friendsOfOmanRoutes: Routes = [
  {
    path: "",
    title: "FRIENDS_OF_OMAN_MUSEUM.DEPARTMENT_PRIVILEGES",
    data: {
      tabs: [
        {
          key: "Memberships",
          title: "FRIENDS_OF_OMAN_MUSEUM.MEMBERSHIPS",
          routerLink: "memberships",
        },
      ],
    },
    loadComponent: () =>
      import("src/app/shared/base-components/base-tabs-management/base-tabs-management.component").then(
        (c) => c.BaseTabsManagementComponent,
      ),
    children: [
      { path: "", redirectTo: "memberships", pathMatch: "full" },
      {
        path: "memberships",
        children: membershipsListRoutes,
      },
    ],
  },
  {
    path: "memberships",
    children: membershipsRoutes,
  },
];
