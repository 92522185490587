import { Routes } from "@angular/router";
import { Permissions } from "src/assets/config/config";

import { servicesRoutes } from "../services";
import { reservationsRoutes } from "./pages/reservations";
import { friendsOfOmanRoutes } from "./pages/friends-of-oman-privilages";
import { usersManagementRoutes } from "./pages/users-management";
import { servicesManagementRoutes } from "./pages/services-management";
import { requestsManagementRoutes } from "./pages/requests-management";

export const adminRoutes: Routes = [
  {
    path: "",
    loadComponent: () => import("./pages/admin/admin.component").then((c) => c.AdminComponent),
    children: [
      { path: "", redirectTo: "booking-services", pathMatch: "full" },
      {
        path: "booking-services",
        data: { permissions: Permissions.BookingServices },
        children: servicesRoutes,
      },
      {
        path: "users-management",
        data: { permissions: Permissions.UsersManagement },
        children: usersManagementRoutes,
      },
      {
        path: "services-management",
        data: { permissions: Permissions.ServicesManagement },
        children: servicesManagementRoutes,
      },
      {
        path: "reservations",
        data: { permissions: Permissions.Reservations },
        children: reservationsRoutes,
      },
      {
        path: "requests-management",
        data: { permissions: Permissions.Requests },
        children: requestsManagementRoutes,
      },
      {
        path: "friends-of-oman-privilages",
        data: { permissions: Permissions.Memberships },
        children: friendsOfOmanRoutes,
      },
    ],
  },
];
