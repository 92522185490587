import { Routes } from "@angular/router";

export const usersListRoutes: Routes = [
  {
    path: "",
    title: "USERS.TITLE",
    loadComponent: () => import("./pages/users-list/users-list.component").then((m) => m.UsersListComponent),
  },
];

export const usersRoutes: Routes = [
  {
    path: "edit/:id",
    title: "USERS.EDIT",
    loadComponent: () => import("./pages/add-edit-user/add-edit-user.component").then((m) => m.AddEditUserComponent),
  },
  {
    path: "add",
    title: "USERS.ADD",
    loadComponent: () => import("./pages/add-edit-user/add-edit-user.component").then((m) => m.AddEditUserComponent),
  },
];
