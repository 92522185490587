import { arEG, enUS } from "date-fns/locale";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { DateFnsAdapter, provideDateFnsAdapter } from "@angular/material-date-fns-adapter";
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { MatCalendar, MatDatepickerInputEvent, MatDatepickerModule } from "@angular/material/datepicker";
import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation, forwardRef, inject } from "@angular/core";

import { DateService } from "src/app/core";

import { IsEnglishDirective } from "../../directives";
import { ValidationHandlerPipe } from "../../pipes";
import { ReactiveFormsBaseComponent } from "../../base-components";

@Component({
  selector: "app-datepicker",
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    ValidationHandlerPipe,
    IsEnglishDirective,
  ],
  templateUrl: "./datepicker.component.html",
  styleUrl: "./datepicker.component.scss",
  encapsulation: ViewEncapsulation.None,
  providers: [
    provideDateFnsAdapter(),
    { provide: MAT_DATE_LOCALE, useValue: enUS },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent extends ReactiveFormsBaseComponent {
  private dateService = inject(DateService);
  private _dateAdapter = inject(DateAdapter<DateFnsAdapter>);

  @Input({ required: true }) label = "";

  @Input() isRangeDate = false;
  @Input() readonly = false;
  @Input() minDate!: Date | null;
  @Input() maxDate!: Date | null;
  @Input() matErrorClass = "";

  @Output() onDateChange = new EventEmitter<string | string[]>();

  @ViewChild(MatCalendar) calendar!: MatCalendar<Date>;

  startDateRange = new FormControl();
  endDateRange = new FormControl();

  setDateValue(event: MatDatepickerInputEvent<Date>) {
    const dateValue: Date | null = event.value;
    if (!dateValue) return;

    const dateFormatted = this.dateService.utc(dateValue);

    this.control.setValue(dateFormatted);
    this.onDateChange.emit(dateFormatted);
  }

  setRangeDateValue(event: MatDatepickerInputEvent<Date>, type: "start" | "end") {
    const dateValue: Date | null = event.value;
    if (!dateValue) return;

    const dateFormatted = this.dateService.utc(dateValue);

    if (type === "start") {
      this.control.setValue([dateFormatted]);
      this.onDateChange.emit(this.control.value);
    } else {
      const startdateValue = JSON.parse(JSON.stringify(this.control.value));

      this.control.setValue([...startdateValue, dateFormatted]);
      this.onDateChange.emit(this.control.value);
    }
  }

  resetDateRangeValue() {
    this.startDateRange.reset();
    this.endDateRange.reset();
    this.control.setValue([]);
    this.onDateChange.emit(this.control.value);
  }

  setCalendarLocale(isEnglish: boolean) {
    this._dateAdapter.setLocale(isEnglish ? enUS : arEG);
    this.calendar?.updateTodaysDate();
  }

  preventDefault(event: KeyboardEvent) {
    event.preventDefault();
  }
}
