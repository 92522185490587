import { toSignal } from "@angular/core/rxjs-interop";
import { finalize, map, of } from "rxjs";
import { inject, Injectable, Signal } from "@angular/core";

import { HttpService } from "../http/http.service";
import { AuthService } from "../auth/auth.service";
import { Checkbox, Dropdown, GetPagedBody, Lookup, ReservationVisitorCategoriesPrice, Role, Service } from "../../interfaces";
import { EmploymentType, MembershipType, ParentServiceCode, ResidenceType, ServiceCodes, TourServicesCodes, UserRole } from "../../enums";

import { MuseumBuildingService } from "src/app/features";
import { Gender } from "../../enums/gender";

@Injectable({
  providedIn: "root",
})
export class LookupsService extends HttpService {
  private _baseUrl = "lookups/";
  private museumBuildingService = inject(MuseumBuildingService);
  private authService = inject(AuthService);

  private set baseUrl(_baseUrl: string) {
    this._baseUrl = _baseUrl;
  }

  protected override get baseUrl(): string {
    return this._baseUrl;
  }

  get isAdminRoute() {
    const userToken = this.convertTokenJWT();
    if (!userToken) return false;

    return [UserRole.ADMIN, UserRole.COUNTER].includes(userToken.RoleCode);
  }

  membershipCardPlans: Signal<Dropdown[] | undefined> | undefined;

  getReservationTypes() {
    return this.get<Lookup[]>({ APIName: "get-reservation-types" });
  }

  getGenders() {
    return of([
      { id: Gender.male, nameEn: "Male", nameAr: "ذكر" },
      { id: Gender.female, nameEn: "Female", nameAr: "أنثى" },
    ] as Dropdown[]);
  }

  getReservationServices() {
    return this.get<Service[]>({ APIName: "get-reservation-services" }).pipe(
      map((services) => {
        services = this.setServiceRoute(services);

        return services.filter((service) => {
          // displaying all the services except the tourism companies service for the company user.
          if (service.code === TourServicesCodes.TourismCompanies && this.authService.user && this.authService.isCompanyUser) return false;

          // displaying the official institutions service only for the official institution user.
          if (
            service.code !== TourServicesCodes.OfficialInstitutions &&
            this.authService.user &&
            this.authService.isOfficialInstitutionUser
          )
            return false;

          // displaying all the services except the official institutions service.
          if (
            service.code === TourServicesCodes.OfficialInstitutions &&
            this.authService.user &&
            !this.authService.isOfficialInstitutionUser
          )
            return false;

          if (service.code === TourServicesCodes.FriendsOfOmanMuseum && this.authService.user && this.authService.isAdmin) return false;

          return true;
        });
      }),
    );
  }

  getReservationVisitorCategoriesPrice(
    reservationTypeCode: ParentServiceCode,
    reservationTicketTypeCode: ServiceCodes,
    museumBuildingId: string | null = null,
  ) {
    this.baseUrl = "reservationprice/";

    const body = {
      reservationTypeCode,
      reservationTicketTypeCode,
      museumBuildingId,
    };

    return this.post<ReservationVisitorCategoriesPrice[]>({
      APIName: `get`,
      body,
    }).pipe(finalize(() => (this.baseUrl = "lookups/")));
  }

  getMuseumBuildingsFreeEntryTicket() {
    const body: GetPagedBody<Record<string, string | number | boolean>> = {
      pageSize: 50,
      pageNumber: 1,
      orderByValue: [
        {
          colId: "id",
          sort: "asc",
        },
      ],
      filter: {
        allowFreeTicket: true,
      },
    };

    return this.museumBuildingService.getPaged(body).pipe(map((response) => response.data));
  }

  getMuseumBuildingsEntranceTicket() {
    const body: GetPagedBody<Record<string, string | number | boolean>> = {
      pageSize: 50,
      pageNumber: 1,
      orderByValue: [
        {
          colId: "id",
          sort: "asc",
        },
      ],
      filter: {
        allowPaidTicket: true,
      },
    };

    return this.museumBuildingService.getPaged(body).pipe(map((response) => response.data));
  }

  getDeviceTypes(reservationTypeCode: ServiceCodes) {
    this.baseUrl = "lookups/";

    return this.get<(Lookup & { price: number })[]>({ APIName: `get-service-types/${reservationTypeCode}` });
  }

  getEventsTypes() {
    return this.get<Service[]>({ APIName: "get-event-types" });
  }

  getRoles() {
    return this.get<Role[]>({ APIName: "get-roles" });
  }

  getTicketTypes() {
    return this.get<Lookup[]>({ APIName: "get-reservation-ticket-types" });
  }

  getProductTypes() {
    this.baseUrl = "producttypes/";
    return this.get<Lookup[]>({ APIName: "getall" });
  }

  getOrganizationTypes(parentCode: keyof typeof ParentServiceCode) {
    this.baseUrl = "lookups/";
    return this.get<Lookup[]>({ APIName: `get-organization-types/${parentCode}` });
  }

  getOrganizationLocations() {
    return this.get<Lookup[]>({ APIName: "get-organization-location" });
  }

  getServiceTypes() {
    return this.get<Lookup[]>({ APIName: "get-service-types" });
  }

  private setServiceRoute(services: Service[]) {
    return services.map((service) => {
      let serviceRoute = "";

      serviceRoute = this.isAdminRoute
        ? `/admin/booking-services/book/${service.parentCode}/${service.code}`
        : `/services/book/${service.parentCode}/${service.code}`;

      return {
        ...service,
        serviceRoute,
      };
    });
  }

  getLanguages() {
    return this.get<Lookup[]>({ APIName: "get-languages" });
  }

  getEducationLevels() {
    return this.get<Lookup[]>({ APIName: "get-educational-levels" });
  }

  getCafeItems() {
    return this.get<Lookup[]>({ APIName: "get-cafe-items" });
  }

  getUserCategories() {
    return this.get<Lookup[]>({ APIName: "get-user-categories" });
  }

  getTargetedCategories() {
    return this.get<Lookup[]>({ APIName: "get-targeted-categories" });
  }

  getTourTypes() {
    return this.get<Lookup[]>({ APIName: "get-tour-types" });
  }

  getCountries(params: { code?: string } = {}) {
    return this.get<Dropdown[]>({ APIName: "get-countries", params });
  }

  getGovernorates(params: { code?: string; id?: number } = {}) {
    return this.get<Dropdown[]>({ APIName: `get-governorates`, params });
  }

  getStates(params: { code?: string; id?: number } = {}) {
    return this.get<Dropdown[]>({ APIName: `get-states`, params });
  }

  assignMembershipCardPlans() {
    if (!this.membershipCardPlans) this.membershipCardPlans = toSignal(this.getMembershipCardPlans());
  }

  getMembershipCardPlans() {
    return this.get<Dropdown[]>({ APIName: "get-membership-card-plans" });
  }

  /**
   * Retrieves a list of membership types.
   *
   * @returns An observable emitting an array of membership types, each containing:
   * - `id`: The identifier of the membership type.
   * - `nameEn`: The English name of the membership type.
   * - `nameAr`: The Arabic name of the membership type.
   */
  getMembershipTypes() {
    return of([
      { id: MembershipType.Person, nameEn: "Person", nameAr: "شخص" },
      { id: MembershipType.Company, nameEn: "Company", nameAr: "شركة" },
    ] as Checkbox[]);
  }

  /**
   * Retrieves a list of employment types.
   *
   * @returns An observable emitting an array of employment types, each containing:
   * - `id`: The identifier of the employment type.
   * - `nameEn`: The name of the employment type in English.
   * - `nameAr`: The name of the employment type in Arabic.
   */
  getEmploymentTypes() {
    return of([
      { id: EmploymentType.Employee, nameEn: "Employee", nameAr: "موظف" },
      { id: EmploymentType.Retired, nameEn: "Retired", nameAr: "متقاعد" },
      { id: EmploymentType.JobSeeker, nameEn: "JobSeeker", nameAr: "باحث عن عمل" },
      { id: EmploymentType.Business, nameEn: "Business", nameAr: "عمل" },
    ] as Lookup[]);
  }

  /**
   * Retrieves the list of residence types.
   *
   * @returns An observable emitting an array of residence types, each containing:
   * - `id`: The identifier of the residence type.
   * - `nameEn`: The English name of the residence type.
   * - `nameAr`: The Arabic name of the residence type.
   */
  getResidenceTypes() {
    return of([
      { id: ResidenceType.Citizen, nameEn: "Citizen", nameAr: "مواطن" },
      { id: ResidenceType.Resident, nameEn: "Resident", nameAr: "مقيم" },
    ] as Lookup[]);
  }
}
