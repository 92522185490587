import { Routes } from "@angular/router";

export const productRoutes: Routes = [
  {
    path: "",
    loadComponent: () =>
      import("./pages/products/products.component").then((c) => c.ProductsComponent),

  },
  {
    path: "cart-products",
    loadComponent: () =>
      import("./pages/cart-products/cart-products.component").then((c) => c.CartProductsComponent),
  },
  {
    path: "invoice/:id",
    loadComponent: () =>
      import("./pages/invoice/invoice.component").then((c) => c.InvoiceComponent),
  }
]
