import { Injectable } from "@angular/core";
import { HttpService, Lookup } from "src/app/core";

@Injectable({
  providedIn: "root",
})
export class ProductCategoriesService extends HttpService {
  protected override get baseUrl(): string {
    return "producttypes/";
  }

  getAll() {
    return this.get<Lookup[]>({ APIName: "getall" });
  }

  addCategory(category: Omit<Lookup, "id">) {
    return this.post<Lookup>({ APIName: "add", body: category });
  }

  updateCategory(category: Lookup) {
    return this.put<Lookup>({ APIName: "update", body: category });
  }

  getCategoryById(id: string) {
    return this.get<Lookup>({ APIName: `get/${id}` });
  }

  removeCategory(id: string) {
    return this.get<Lookup>({ APIName: `deletesoft/${id}` });
  }
}
