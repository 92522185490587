import { first } from "rxjs";
import { TranslateModule } from "@ngx-translate/core";
import { Component, ViewEncapsulation, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";

import { ButtonComponent } from "../button/button.component";
import { IsEnglishDirective } from "../../directives";

@Component({
  selector: "app-confirmation-dialog",
  standalone: true,
  imports: [TranslateModule, MatDialogModule, IsEnglishDirective, ButtonComponent],
  templateUrl: "./confirmation-dialog.component.html",
  styleUrl: "./confirmation-dialog.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationDialogComponent {
  private data = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef<ConfirmationDialogComponent>);

  isEnglish = true;
  message = this.data?.message ? this.data.message : "confirmation_message";
  messageClass = this.data?.messageClass ? this.data.messageClass : "text-danger";

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    this.dialogRef
      .afterOpened()
      .pipe(first())
      .subscribe(() => {
        const cdkOverlayWrapper = document.querySelector(".cdk-global-overlay-wrapper");
        if (cdkOverlayWrapper) cdkOverlayWrapper.setAttribute("dir", this.isEnglish ? "ltr" : "rtl");
      });
  }
}
