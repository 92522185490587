import { Routes } from "@angular/router";

export const requestsManagementRoutes: Routes = [
  {
    path: "",
    title: "REQUESTS.MANAGEMENT",
    data: {
      tabs: [
        {
          key: "SchoolUniversityVisits",
          title: "SERVICES.SchoolUniversityVisits",
          routerLink: "school-university-visits",
        },
        {
          key: "OfficialInstitutions",
          title: "OFFICIAL_INSTITUTIONS.TITLE",
          routerLink: "official-institutions",
        },
        {
          key: "FriendsOfOmanMuseum",
          title: "SERVICES.FriendsOfOmanMuseum",
          routerLink: "friends-of-oman-museum",
        },
      ],
    },
    loadComponent: () =>
      import("src/app/shared/base-components/base-tabs-management/base-tabs-management.component").then(
        (c) => c.BaseTabsManagementComponent,
      ),
    children: [
      {
        path: "",
        redirectTo: "school-university-visits",
        pathMatch: "full",
      },
      {
        path: "school-university-visits",
        data: { reservationTicketType: 2 },
        loadComponent: () =>
          import("./pages/reservation-requests/reservation-requests.component").then((c) => c.ReservationRequestsComponent),
      },
      {
        path: "official-institutions",
        data: { reservationTicketType: 7 },
        loadComponent: () =>
          import("./pages/reservation-requests/reservation-requests.component").then((c) => c.ReservationRequestsComponent),
      },
      {
        path: "friends-of-oman-museum",
        loadComponent: () => import("./pages/membership-requests/membership-requests.component").then((c) => c.MembershipRequestsComponent),
      },
    ],
  },
];
