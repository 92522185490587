import { map } from "rxjs";
import { Injectable } from "@angular/core";

import { OfficialInstitution, OfficialInstitutionPayload } from "../../interfaces";

import { GetPagedBody, GetPagedResponse, HttpService, HttpServiceBaseCRUD } from "src/app/core";

@Injectable({
  providedIn: "root",
})
export class OfficialInstitutionsService
  extends HttpService
  implements HttpServiceBaseCRUD<OfficialInstitution, OfficialInstitutionPayload>
{
  protected get baseUrl(): string {
    return "officialinstitute/";
  }

  add(body: OfficialInstitutionPayload) {
    return this.post<OfficialInstitution>({ APIName: "add", body, showAlert: true });
  }

  update(body: OfficialInstitutionPayload) {
    return this.put<OfficialInstitution>({ APIName: "update", body, showAlert: true });
  }

  remove(id: string) {
    return this.delete({ APIName: `deletesoft/${id}`, showAlert: true });
  }

  getById(id: string) {
    return this.get<OfficialInstitution>({ APIName: `get/${id}` });
  }

  getAll() {
    return this.get<OfficialInstitution[]>({ APIName: "getall" });
  }

  getPaged(body: GetPagedBody<Record<string, string | number | boolean>>) {
    return this.post<GetPagedResponse<OfficialInstitution[]>>({ APIName: "getpaged", body });
  }

  getCurrentOfficialInstitution() {
    return this.getPaged({
      pageNumber: 1,
      pageSize: 1,
      filter: { userId: this.convertTokenJWT()!.UserId },
      orderByValue: [
        {
          colId: "id",
          sort: "asc",
        },
      ],
    }).pipe(map((res) => (res.data.length ? res.data[0] : null)));
  }
}
