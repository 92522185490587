import { Routes } from "@angular/router";

import { packagesManagementRoutes, packagesListRoutes } from "./nested-features/packages";
import { guidedToursManagementRoutes, guidedToursRoutes } from "./nested-features/guided-tours-programs";

import { Permissions } from "src/assets/config/config";
import { productsManagementRoutes, productsRoutes } from "./nested-features/products";
import { visitorPricesManagementRoutes, visitorPricesRoutes } from "./nested-features/visitor-prices";
import { museumManagementRoutes, museumRoutes } from "./nested-features/museum-buildings/museums-buildings-routes";

export const servicesManagementRoutes: Routes = [
  {
    path: "",
    title: "SERVICES.SERVICES_MANAGEMENT",
    data: {
      tabs: [
        {
          key: "Packages",
          title: "SERVICES.Packages",
          routerLink: "packages",
        },
        {
          key: "GuidedTours",
          title: "SERVICES.GuidedTours",
          routerLink: "guided-tours",
        },
        {
          key: "Products",
          title: "SERVICES.Products",
          routerLink: "products",
        },
        {
          key: "VisitorPrices",
          title: "VISITOR_PRICES.TITLE",
          routerLink: "visitor-prices",
        },
        {
          key: "Museums",
          title: "MUSEUM_BUILDINGS.TITLE",
          routerLink: "museums",
        },
      ],
    },
    loadComponent: () =>
      import("src/app/shared/base-components/base-tabs-management/base-tabs-management.component").then(
        (c) => c.BaseTabsManagementComponent,
      ),
    children: [
      { path: "", redirectTo: "packages", pathMatch: "full" },
      {
        path: "packages",
        data: { permissions: Permissions.Packages },
        children: packagesListRoutes,
      },
      {
        path: "guided-tours",
        data: { permissions: Permissions.GuidedTours },
        children: guidedToursRoutes,
      },
      {
        path: "products",
        data: { permissions: Permissions.Product },
        children: productsRoutes,
      },
      {
        path: "visitor-prices",
        data: { permissions: Permissions.VisitorPrices },
        children: visitorPricesRoutes,
      },
      {
        path: "museums",
        data: { permissions: Permissions.Museums },
        children: museumRoutes,
      },
    ],
  },
  {
    path: "packages",
    data: { permissions: Permissions.Packages },
    children: packagesManagementRoutes,
  },
  {
    path: "guided-tours",
    data: { permissions: Permissions.GuidedTours },
    children: guidedToursManagementRoutes,
  },
  {
    path: "products",
    data: { permissions: Permissions.Product },
    children: productsManagementRoutes,
  },
  {
    path: "visitor-prices",
    data: { permissions: Permissions.VisitorPrices },
    children: visitorPricesManagementRoutes,
  },
  {
    path: "museums",
    data: { permissions: Permissions.Museums },
    children: museumManagementRoutes,
  },
];
