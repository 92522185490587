<div class="modal-container d-flex flex-column" (isEnglish)="isEnglish = $event">
  <div class="modal-header bg-header justify-content-between align-items-center w-100" mat-dialog-header>
    <h1 class="m-0 p-0 font-20 text-dark fw-bold w-100" mat-dialog-title>
      {{ "ACTIONS.CONFIRMATION" | translate }}
    </h1>
  </div>

  <div class="px-4 py-3 m-0" mat-dialog-content>
    <p class="m-0 font-14 {{ messageClass }}">{{ message | translate }}</p>
  </div>

  <div class="modal-footer w-100 d-flex justify-content-start gap-3" mat-dialog-actions>
    <app-button btnLabel="ACTIONS.CONFIRM" color="warn" (onClick)="dialogRef.close(true)" />
    <app-button btnLabel="ACTIONS.CANCEL" (onClick)="dialogRef.close(false)" />
  </div>
</div>
