import { Routes } from "@angular/router";

import { usersListRoutes, usersRoutes } from "./nested-features/users";
import { officialInstitutionsListRoutes, officialInstitutionsRoutes } from "./nested-features/official-institutions";
import { companiesListRoutes, companiesManagementRoutes } from "./nested-features/companies";

export const usersManagementRoutes: Routes = [
  {
    path: "",
    title: "USERS.USERS_MANAGEMENT",
    data: {
      tabs: [
        {
          key: "Users",
          title: "USERS.TITLE",
          routerLink: "users",
        },
        {
          key: "OfficialInstitutions",
          title: "OFFICIAL_INSTITUTIONS.TITLE",
          routerLink: "official-institutions",
        },
        {
          key: "Companies",
          title: "COMPANIES.TITLE",
          routerLink: "companies",
        },
      ],
    },
    loadComponent: () =>
      import("src/app/shared/base-components/base-tabs-management/base-tabs-management.component").then(
        (c) => c.BaseTabsManagementComponent,
      ),
    children: [
      { path: "", redirectTo: "users", pathMatch: "full" },
      {
        path: "users",
        children: usersListRoutes,
      },
      {
        path: "official-institutions",
        children: officialInstitutionsListRoutes,
      },
      {
        path: "companies",
        children: companiesListRoutes,
      },
    ],
  },
  {
    path: "users",
    children: usersRoutes,
  },
  {
    path: "official-institutions",
    children: officialInstitutionsRoutes,
  },
  {
    path: "companies",
    children: companiesManagementRoutes,
  },
];
