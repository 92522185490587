<div class="alert-wrapper d-flex align-items-center position-absolute zi-5 {{ customClass() }}">
  <div class="alert-icon bg-white px-4 d-flex align-items-center justify-content-center h-100">
    <p class="m-0 text-dark-red font-30 fw-bold">!</p>
  </div>

  <div
    class="alert alert-dark-red d-flex align-items-center justify-content-between w-max m-0 h-100 py-0"
    [class.gap-2]="showAlert"
    [class.px-0]="!showAlert"
    role="alert"
  >
    <p class="m-0 font-10 text-truncate-3 alert-text py-2" [class.w-0]="!showAlert">{{ text() | translate: additionalText() }}</p>
    <em
      class="text-white cursor-pointer d-flex align-items-center h-100"
      [class.db-icon-plus]="showAlert"
      [class.rotate-45]="showAlert"
      [class.db-icon-chevron-right]="!showAlert"
      [class.px-1]="!showAlert"
      (click)="showAlert = !showAlert"
    ></em>
  </div>
</div>
