@if (label) {
  <label class="text-primary fw-bold">{{ label }}</label>
}
<mat-slider [min]="min" [max]="max" [step]="step" class="w-100">
  <input matSliderStartThumb [value]="controlValue[0]" (input)="updateValue([+$event.target.value, controlValue[1]])" />
  <input matSliderEndThumb [value]="controlValue[1]" (input)="updateValue([controlValue[0], +$event.target.value])" />
</mat-slider>
<div class="d-flex justify-content-between text-primary font-14 fw-bold">
  <span>{{ controlValue[0] }} {{ unit | translate }}</span>
  <span>{{ controlValue[1] }} {{ unit | translate }}</span>
</div>
