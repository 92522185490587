import { Routes } from "@angular/router";

export const officialInstitutionsListRoutes: Routes = [
  {
    path: "",
    title: "OFFICIAL_INSTITUTIONS.TITLE",
    loadComponent: () =>
      import("./pages/official-institutions-list/official-institutions-list.component").then((c) => c.OfficialInstitutionsListComponent),
  },
];

export const officialInstitutionsRoutes: Routes = [
  {
    path: "add",
    title: "OFFICIAL_INSTITUTIONS.ADD",
    loadComponent: () =>
      import("./pages/add-edit-official-institution/add-edit-official-institution.component").then(
        (c) => c.AddEditOfficialInstitutionComponent,
      ),
  },
  {
    path: "edit/:id",
    title: "OFFICIAL_INSTITUTIONS.EDIT",
    loadComponent: () =>
      import("./pages/add-edit-official-institution/add-edit-official-institution.component").then(
        (c) => c.AddEditOfficialInstitutionComponent,
      ),
  },
];
