import { Injectable } from "@angular/core";

import { Attachment } from "../../interfaces";
import { HttpService } from "../http/http.service";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AttachmentService extends HttpService {
  get baseUrl(): string {
    return "Files/";
  }

  upload(body: FormData, isPublic: boolean) {
    const APIName = `UploadToSanStorage?storageType=2&isPublic=${isPublic}&appCode=${environment.attachmentAppCode}`;

    return this.post<Attachment[]>({ APIName, body });
  }

  deleteAttachment(fileId: string) {
    return this.get<boolean>({ APIName: `Delete/${fileId}`, showAlert: true });
  }
}
