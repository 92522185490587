import { Injectable } from "@angular/core";
import { GetPagedBody, GetPagedResponse, HttpService, Product } from "src/app/core";

@Injectable({
  providedIn: "root",
})
export class OrdersService extends HttpService {
  protected override get baseUrl(): string {
    return "orders/";
  }

  addOrder(order: any) {
    return this.post<any>({ APIName: "add", body: order });
  }

  checkProductQuantity(order: any) {
    return this.post<any>({ APIName: "checkproductitemvalidation", body: order });
  }

  getInvoiceByOrderId(orderId: string) {
    return this.get<any>({ APIName: `invoicebyid/${orderId}` })
  }
}
