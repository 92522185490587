import { Routes } from "@angular/router";

export const visitorPricesRoutes: Routes = [
  {
    path: "",
    title: "VISITOR_PRICES.TITLE",
    loadComponent: () => import("./pages/visitor-prices-list/visitor-prices-list.component").then((m) => m.VisitorPricesListComponent),
  },
];

export const visitorPricesManagementRoutes: Routes = [
  {
    path: "add",
    title: "VISITOR_PRICES.ADD",
    loadComponent: () =>
      import("./pages/add-edit-visitor-price/add-edit-visitor-price.component").then((m) => m.AddEditVisitorPriceComponent),
  },
  {
    path: "edit/:id",
    title: "VISITOR_PRICES.EDIT",
    loadComponent: () =>
      import("./pages/add-edit-visitor-price/add-edit-visitor-price.component").then((m) => m.AddEditVisitorPriceComponent),
  },
];
