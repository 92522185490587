import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { Subscription, debounceTime } from "rxjs";
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { Component, EventEmitter, HostListener, Input, Output, ViewEncapsulation, forwardRef } from "@angular/core";

import { ValidationHandlerPipe } from "../../pipes";
import { ReactiveFormsBaseComponent } from "../../base-components";

@Component({
  selector: "app-input-text",
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, MatInputModule, ValidationHandlerPipe],
  templateUrl: "./input-text.component.html",
  styleUrl: "./input-text.component.scss",
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true,
    },
  ],
})
export class InputTextComponent extends ReactiveFormsBaseComponent {
  @Input({ required: true }) label!: string;
  @Input() placeholder!: string;
  @Input() inputType: "textbox" | "textarea" = "textbox";
  @Input() contentType: "text" | "email" | "password" | "number" = "text";
  @Input() readonly = false;
  @Input() matErrorClass = "";

  @Output() inputValue = new EventEmitter();

  private controlSubscription!: Subscription;

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    this.controlSubscription = this.control.valueChanges.pipe(debounceTime(100)).subscribe((value) => this.inputValue.emit(value));
  }

  onKeyPress(event: KeyboardEvent): void {
    if (this.contentType === "number" && ["e", "+", "-", "."].includes(event.key.toLowerCase())) {
      event.preventDefault();
    }
  }

  @HostListener("paste", ["$event"]) onPaste(event: ClipboardEvent): void {
    if (this.contentType === "number" && ["e", "+", "-", "."].includes(event.clipboardData?.getData("text/plain").toLowerCase()!)) {
      event.preventDefault();
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.controlSubscription.unsubscribe;
  }
}
