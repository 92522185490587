import { ToastrService } from "ngx-toastr";
import { Observable, map } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Injectable, inject } from "@angular/core";
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from "@angular/common/http";

import { UserRole } from "../../enums";
import { environment } from "../../../../environments/environment";
import { API_Response, GetPagedBody, GetPagedResponse, HttpStatus } from "../../interfaces";

export declare interface HttpServiceBaseCRUD<T = unknown, K = unknown> {
  add(body: Partial<K>): Observable<T>;
  update(body: Partial<K>): Observable<T>;
  remove(id: string): Observable<boolean>;
  getById(id: string): Observable<T>;
  getAll(): Observable<T[]>;
  getPaged(body: GetPagedBody<Partial<unknown>>): Observable<GetPagedResponse<T[]>>;
}

interface URL_Config {
  APIName: string;
  body?: unknown;
  showAlert?: boolean;

  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  observe?: "body";
  params?:
    | HttpParams
    | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: "json";
  withCredentials?: boolean;
  transferCache?:
    | {
        includeHeaders?: string[];
      }
    | boolean;
}

interface UserToken {
  Email: string;
  NameEn: string;
  Phone: string;
  Role: string;
  RoleCode: UserRole;
  RoleNameAr: string;
  RoleNameEn: string;
  UserId: string;
  DiscountType: string;
  DiscountValue: string;
  Amount: string;
  EntranceType: string;
  aud: string;
  exp: number;
  iss: string;
  jti: string;
  sub: string;
}

@Injectable({
  providedIn: "root",
})
export abstract class HttpService {
  private http = inject(HttpClient);
  toastrService = inject(ToastrService);
  translation = inject(TranslateService);

  protected abstract get baseUrl(): string;

  get<T>(URL_Config: URL_Config) {
    return this.http.get<API_Response<T>>(`${this.baseUrl}${URL_Config.APIName}`, { ...URL_Config }).pipe(
      map((event) => {
        if (URL_Config.showAlert && event.status === HttpStatus.Ok) this.alertHandling(event);

        if (event.data) return event.data;

        return event as T;
      }),
    );
  }

  post<T>(URL_Config: URL_Config) {
    return this.http.post<API_Response<T>>(`${this.baseUrl}${URL_Config.APIName}`, URL_Config.body, { ...URL_Config }).pipe(
      map((event) => {
        if (URL_Config.showAlert && [HttpStatus.Ok, HttpStatus.Created, HttpStatus.NoContent].includes(event.status))
          this.alertHandling(event);

        if (!event.data && [HttpStatus.BadRequest, HttpStatus.NotFound].includes(event.status)) {
          throw new Error(event.message);
        }

        return event.data;
      }),
    );
  }

  put<T>(URL_Config: URL_Config): Observable<T> {
    return this.http.put<API_Response<T>>(`${this.baseUrl}${URL_Config.APIName}`, URL_Config.body, { ...URL_Config }).pipe(
      map((event) => {
        this.alertHandling(event);
        return event.data;
      }),
    );
  }

  delete<T = boolean>(URL_Config: URL_Config): Observable<T> {
    return this.http.delete<API_Response<T>>(`${this.baseUrl}${URL_Config.APIName}`, { ...URL_Config }).pipe(
      map((event) => {
        this.alertHandling(event);
        return event.data;
      }),
    );
  }

  private alertHandling(event: API_Response<unknown>) {
    if (event.status) {
      if (event.status.toString().startsWith("2")) {
        this.toastrService.success(event.message ? this.translation.instant(`BE_VALIDATION.${event.message}`) : "Successfully Done...");
      } else {
        this.toastrService.error(event.message ? this.translation.instant(`BE_VALIDATION.${event.message}`) : "!NOT HANDLED ERROR!");
      }
    }
  }

  convertTokenJWT(token = localStorage.getItem(environment.tokenPropertyName) as string) {
    if (!token) return null;

    let base64 = token.split(".")[1].replace(/-/g, "+").replace(/_/g, "/"),
      jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(""),
      );

    return JSON.parse(jsonPayload) as UserToken;
  }
}
