import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-floating-note",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./floating-note.component.html",
  styleUrl: "./floating-note.component.scss",
})
export class FloatingNoteComponent {
  text = input.required<string>();
  additionalText = input<Record<string, unknown>>();
  customClass = input("", { alias: "class" });

  showAlert = true;
}
