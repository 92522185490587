import { Routes } from "@angular/router";

export const productsManagementRoutes: Routes = [
  {
    path: "add",
    title: "PRODUCTS.ADD",
    loadComponent: () => import("./pages/add-edit-product/add-edit-product.component").then((m) => m.AddEditProductComponent),
  },
  {
    path: "edit/:id",
    title: "PRODUCTS.EDIT",
    loadComponent: () => import("./pages/add-edit-product/add-edit-product.component").then((m) => m.AddEditProductComponent),
  },
];

export const productsRoutes: Routes = [
  {
    path: "",
    title: "SERVICES.PRODUCTS",
    loadComponent: () => import("./pages/products-list/products-list.component").then((m) => m.ProductsListComponent),
  },
];
