<div class="form-group d-flex flex-column m-0" [class.mb-3]="control.errors && control.touched">
  <label class="text-primary fw-bold mb-1" for="attachment">
    {{ label | translate }}

    @if (isRequired) {
      <span>*</span>
    }
  </label>

  @if (!isDisabled && form) {
    <div class="attachment">
      <div class="attachment__header mb-4" [class.mb-4]="selectedFiles.length || (control.errors && control.touched)">
        @if (isFailed) {
          <div class="d-flex align-items-center mb-2">
            <span class="failed">{{ "Attachment.Failed" | translate }}</span>
          </div>
        }

        <div
          class="upload text-primary d-flex align-items-center justify-content-between"
          [class.disabled]="isHidden"
          (click)="isHidden || maxFilesNumbers === selectedFiles.length ? '' : file.click()"
        >
          <span>{{ label | translate }}</span>

          <em class="db-icon-file font-20" [class.cursor-pointer]="!isHidden && maxFilesNumbers !== selectedFiles.length"></em>
        </div>

        <input
          #file
          id="attachment"
          class="form-control textField d-none"
          type="file"
          [multiple]="isMultiple"
          [accept]="acceptedTypes"
          (change)="onUploadFile($event)"
        />
      </div>

      @if (selectedFiles.length) {
        <div class="attachment__files-wrapper">
          @for (file of selectedFiles; track $index) {
            <div [class.mb-3]="$index < selectedFiles.length - 1">
              <div class="attachment__file d-flex align-items-center justify-content-between gap-2" [class.bg-error]="isFailed">
                <div class="file-details d-flex align-items-center gap-2">
                  @if (filesExtensions.includes(file.extension)) {
                    <em class="db-icon-download text-gradient-success font-26"></em>
                  }

                  @if (imageExtensions.includes(file.extension)) {
                    <img [src]="file.url" />
                  }

                  <span class="text-truncate file-name font-10" for="file" [title]="file.fileName">{{ file.fileName }}</span>

                  @if (!isFailed && file.attachmentDisplaySize) {
                    <span class="CardSubTitle file-size font-10">( {{ file.attachmentDisplaySize }} )</span>
                  }
                </div>

                <span class="d-flex align-items-center justify-content-center">
                  @if (!isFailed && file.isSuccess) {
                    <em
                      class="db-icon-trash text-gradient-danger font-20 icons"
                      (click)="file.isSuccess ? removeFile($index, file) : ''"
                    ></em>
                  }

                  @if (!file.isSuccess) {
                    <em class="db-icon-loader spinner d-block font-20"></em>
                  }

                  @if (isFailed) {
                    <em class="db-icon-upload text-primary font-20 icons" (click)="saveFile(uploadedFiles)"></em>
                  }
                </span>
              </div>
            </div>
          }
        </div>
      }

      @if (control.errors && control.touched) {
        <mat-error>
          <span class="font-10 text-danger">
            {{ control.errors | validationHandler }}
          </span>
        </mat-error>
      }
    </div>
  }
</div>
